import Community from "./community";
import { KeyValue } from "./keyValue";
import PollAnswer from "./pollAnswer";
import Base from "./base";
import Session from "./session";

export default class Poll extends Base {
  community?: Community;
  title?: string;
  startTime?: string;
  endTime?: string;
  startTimestamp?: number;
  endTimestamp?: number;
  pollAnswers?: Array<PollAnswer>;
  session?: Session;
  answerCountByAnswerId?: Array<KeyValue>;
  // _myAnswer?: PollUserAnswer[];

  public static hydrate(obj: any): Poll {
    const poll = new Poll();
    Object.assign(poll, obj);
    return poll;
  }
  public get isPublished(): boolean {
    return !!this.startTime;
  }
}
