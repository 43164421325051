import { getLocalStorageKey, hide, hideAll, show } from "../scripts/utils";
import PowerpointHelper from "../scripts/powerpointHelper";
import PollController from "./pollController";
import SetupController from "./setupController";
import { ActiveView } from "../models/activeView";
import ViewParameters from "../scripts/types/viewParameters";

export default class MainController {
  private token = "";
  private sessionUid = "";
  private pollUid = "";
  private action: string | undefined;
  private closeIn: string | undefined;
  private showLiveResults: boolean;
  private currentSlide = 0;
  private activeView: ActiveView = "edit";
  private presentationTitle: string;
  private presentationSubtitle: string;
  private showQRCode: boolean;
  private qrCodeUrl: string;

  private pollController: PollController | undefined;
  private setupController: SetupController | undefined;

  constructor(info: { host: Office.HostType; platform: Office.PlatformType }) {
    if (info.host !== Office.HostType.PowerPoint) {
      console.warn("SHERPA:: host type not supported. Sherpa Live Poll add-in is only compatible with Powerpoint.");
      return;
    }

    show("app-body");
    hideAll();

    PowerpointHelper.registerActiveViewListener((view: ActiveView) => {
      this.activeView = view;
      this.startView();
    });

    setInterval(async () => {
      const currentSlide = await PowerpointHelper.getCurrentSlideId();
      const currentActiveView = await PowerpointHelper.getActiveView();
      if (currentSlide !== this.currentSlide || currentActiveView !== this.activeView) {
        this.startView();
      }
    }, 500);
  }

  public async startView() {
    this.activeView = await PowerpointHelper.getActiveView();
    this.currentSlide = await PowerpointHelper.getCurrentSlideId();
    let data: any;
    let savedData: ViewParameters;
    const key = getLocalStorageKey(this.currentSlide);
    data = PowerpointHelper.getSetting(key);
    // if data is not in settings then check in sessionStorage, if found in sessionStorage then save it in settings
    if (data === "null") {
      PowerpointHelper.removeSetting(key);
    }
    if (data === null || data === "null") {
      data = sessionStorage.getItem(key);
      if (data === "null") {
        sessionStorage.removeItem(key);
      }
      if (data && data !== "null") {
        PowerpointHelper.saveSetting(key, data);
        savedData = JSON.parse(data) as ViewParameters;
      }
    } else {
      savedData = JSON.parse(data) as ViewParameters;
      // the reason why I load the data in sessionStorage is to make the data accessible to all the add-in instances
      // because settings is only accessible to its instance
      sessionStorage.setItem(key, data);
    }

    if (savedData) {
      this.token = savedData.token;
      this.sessionUid = savedData.sessionUid;
      this.pollUid = savedData.pollUid;
      this.action = savedData.action;
      this.closeIn = savedData.closeIn;
      this.showLiveResults = savedData.showLiveResults;
      this.presentationTitle = savedData.presentationTitle;
      this.presentationSubtitle = savedData.presentationSubtitle;
      this.showQRCode = savedData.showQRCode ?? true;
      this.qrCodeUrl = savedData.qrCodeUrl || "";
    } else {
      this.token = "";
      this.sessionUid = "";
      this.pollUid = undefined;
      this.action = undefined;
      this.closeIn = undefined;
      this.showLiveResults = false;
      this.presentationTitle = "";
      this.presentationSubtitle = "";
      this.showQRCode = true;
      this.qrCodeUrl = "";
    }
    const parameters = new ViewParameters(
      this.token,
      this.sessionUid,
      this.pollUid,
      this.action,
      this.closeIn,
      this.showLiveResults,
      this.activeView,
      this.currentSlide,
      this.presentationTitle,
      this.presentationSubtitle,
      this.showQRCode,
      this.qrCodeUrl
    );

    console.log("SHERPA:: Loaded: ", parameters);

    try {
      // destroy existing controllers
      if (this.setupController) {
        this.setupController.destroy();
      }
      if (this.pollController) {
        this.pollController.destroy();
      }
      // Start frame
      if (this.activeView === "read") {
        this.pollController = new PollController(parameters);
        this.pollController.startView();
      } else {
        this.setupController = new SetupController(parameters);
        this.setupController.startView();
      }
    } catch (e) {
      console.error(e);
    }
  }
}
