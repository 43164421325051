export default `
    query GetCommunity($code: String) {
        community(_cachable: true, filter: { code: $code }) {
            ...communityFragment
        }
    } 
    
    fragment communityFragment on Community {
        uid
        code
        shortName
        timeZone
        longName
        description
        pollSessionPathQRCode
        masterCommunity {
            masterEditions {
              code
              edition {
                name
                startTime
                endTime
                venue {
                  address {
                    city
                    state
                  }
                }
              }
            }
        }
    }
`;
