enum NotificationEventType {
  CONNECTION_REQUEST = "CONNECTION_REQUEST",
  COMPANY_INVITE = "COMPANY_INVITE",
  MEETING_PARTICIPANT = "MEETING_PARTICIPANT",
  MEETING = "MEETING",
  SESSION = "SESSION",
  FEED_POST_REPORT_CREATE = "FEED_POST_REPORT_CREATE",
  FEED_POST_REPORT_DELETE = "FEED_POST_REPORT_DELETE",
  MEETING_REQUEST = "MEETING_REQUEST",
  POLL_ANSWER = "POLL_ANSWER",
  POLL_PUBLISH = "POLL_PUBLISH",
  POLL_CLOSE = "POLL_CLOSE",
  POLL_DELETE = "POLL_DELETE",
  POST_COMMENT_CREATE = "POST_COMMENT_CREATE",
  POST_COMMENT_CREATE_REPLY = "POST_COMMENT_CREATE_REPLY",
  POST_COMMENT_EDIT = "POST_COMMENT_EDIT",
  POST_COMMENT_DELETE = "POST_COMMENT_DELETE",
  POST_COMMENT_LIKE = "POST_COMMENT_LIKE",
  POST_LIKE = "POST_LIKE",
  QA_GROUP_CREATE = "QA_GROUP_CREATE",
  QA_NEW_QUESTION = "QA_NEW_QUESTION",
  QA_DELETE_QUESTION = "QA_DELETE_QUESTION",
  QA_NEW_ANSWER = "QA_NEW_ANSWER",
  QA_EDIT_ANSWER = "QA_EDIT_ANSWER",
  QA_DELETE_ANSWER = "QA_DELETE_ANSWER",
  QA_OPEN = "QA_OPEN",
  QA_CLOSE = "QA_CLOSE",
  SURVEY = "SURVEY",
  MICROPOLL = "MICROPOLL",
}

export default NotificationEventType;