import Base from "./base";
import FileResource from "./fileResource";

class Community extends Base {
  code?: string;
  longName?: string;
  timeZone?: string;
  shortName?: string;
  description?: string;
  pollSessionPathQRCode?: string;
  matomoIdSite?: number;
  advertisementNetworkId?: number;
  landingCSSConfig?: string;
  logoFileResource: FileResource;
  favIconFileResource?: FileResource;
  defaultExhibitorLogoFileResource?: FileResource;
  defaultExhibitorBannerFileResource?: FileResource;
  defaultSessionBannerFileResource?: FileResource;
  defaultUserBannerFileResource?: FileResource;
  defaultDealBannerFileResource?: FileResource;
  termsOfService?: string;
  privacyPolicy?: string;

  public static hydrate(obj: any): Community {
    const community = new Community();
    Object.assign(community, obj);
    return community;
  }
}

export default Community;
