export default class DateTimeHelper {
  static readonly TIME_FORMAT_ISO_8601 = "yyyy-MM-dd'T'HH:mm:ss";

  static toLocal(date: Date, offset?: number | null): Date {
    if (offset) {
      return Math.sign(offset) === -1
        ? DateTimeHelper.subMinutes(date, Math.abs(offset))
        : DateTimeHelper.addMinutes(date, offset);
    }
    const currentOffset = date.getTimezoneOffset();
    return Math.sign(currentOffset) === -1
      ? DateTimeHelper.addMinutes(date, currentOffset)
      : DateTimeHelper.subMinutes(date, Math.abs(currentOffset));
  }

  static toUTC(date: Date, offset?: number | null): Date {
    if (offset) {
      return Math.sign(offset) === -1
        ? DateTimeHelper.addMinutes(date, Math.abs(offset))
        : DateTimeHelper.subMinutes(date, offset);
    }
    const currentOffset = date.getTimezoneOffset();
    return Math.sign(currentOffset) !== -1
      ? DateTimeHelper.addMinutes(date, currentOffset)
      : DateTimeHelper.subMinutes(date, Math.abs(currentOffset));
  }

  static toISO(date: Date): string {
    return date.toISOString().substring(0, 19);
  }

  static getCurrentDateTime(): Date {
    return new Date();
  }

  static addSeconds(date: Date, seconds: number): Date {
    return new Date(date.getTime() + seconds * 1000);
  }

  static addMinutes(date: Date, minutes: number): Date {
    return new Date(date.getTime() + minutes * 60000);
  }

  static addHours(date: Date, hours: number): Date {
    return new Date(date.getTime() + hours * 60000 * 60);
  }

  static addDays(date: Date, days: number): Date {
    return new Date(date.getTime() + days * 60000 * 60 * 24);
  }

  static subMinutes(date: Date, minutes: number): Date {
    return new Date(date.getTime() - minutes * 60000);
  }

  static differenceInSeconds(date1: Date, date2: Date) {
    // @ts-ignore
    const diffTime = Math.abs(date2 - date1);
    return Math.floor(diffTime / 1000);
  }

  static differenceInMinutes(date1: Date, date2: Date) {
    // @ts-ignore
    const diffTime = Math.abs(date2 - date1);
    return Math.floor(diffTime / (1000 * 60));
  }

  static differenceInHours(date1: Date, date2: Date) {
    // @ts-ignore
    const diffTime = Math.abs(date2 - date1);
    return Math.floor(diffTime / (1000 * 60 * 60));
  }

  static differenceInDays(date1: Date, date2: Date) {
    // @ts-ignore
    const diffTime = Math.abs(date2 - date1);
    return Math.floor(diffTime / (1000 * 60 * 60 * 24));
  }
}
