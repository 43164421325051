import { decodeToken, getLocalStorageKey, hide, hideAll, isTokenValid, show } from "../scripts/utils";
import PollController from "./pollController";
import POLL_GET_QUERY from "../graphql/pollGet";
import SESSION_GET_QUERY from "../graphql/sessionGet";
import Poll from "../models/poll";
import GraphqlRepository from "../scripts/graphqlRepository";
import configs from "../scripts/configs";
import { ActiveView } from "../models/activeView";
import POLL_UPDATE_MUTATION from "../graphql/pollUpdate";
import POLL_USER_ANSWER_DELETE_MUTATION from "../graphql/pollUserAnswerDelete";
import ViewParameters from "../scripts/types/viewParameters";
import Session from "../models/session";
import PowerpointHelper from "../scripts/powerpointHelper";
import COMMUNITY_GET_QUERY from "../graphql/communityGet";
import Community from "../models/community";

export default class SetupController {
  private token: string;
  private sessionUid: string;
  private pollUid: string;
  private slideNum: number;
  private activeView: ActiveView;
  private action: string | undefined;
  private closeInValue: string | undefined;
  private closeInUnit: string | undefined;
  private showResults: boolean;
  private presentationTitle: string;
  private presentationSubtitle: string;
  private showQRCode: boolean;
  private qrCodeUrl: string;

  private community: Community | undefined;
  private poll: Poll | undefined;
  private session: Session | undefined;
  private gqlRepository: GraphqlRepository;
  private activeDebouncer: number | undefined;
  private currentResetPollStep: number;

  constructor(viewParameters: ViewParameters) {
    this.initData(viewParameters);
  }

  private initData(viewParameters: ViewParameters) {
    const {
      token,
      sessionUid,
      pollUid,
      action,
      closeIn,
      showLiveResults,
      activeView,
      slideNum,
      presentationTitle,
      presentationSubtitle,
      showQRCode,
      qrCodeUrl,
    } = viewParameters;
    this.token = token;
    this.sessionUid = sessionUid;
    this.pollUid = pollUid;
    this.action = action || "open";
    this.closeInUnit = closeIn !== undefined ? viewParameters.closeInUnit : "";
    this.closeInValue = closeIn !== undefined ? viewParameters.closeInValue : "";
    this.showResults = showLiveResults;
    this.activeView = activeView;
    this.slideNum = slideNum;
    this.presentationTitle = presentationTitle;
    this.presentationSubtitle = presentationSubtitle;
    this.showQRCode = showQRCode;
    this.qrCodeUrl = qrCodeUrl;
    this.gqlRepository = new GraphqlRepository(configs.AGGREGATOR_URL);
    if (this.token) {
      this.gqlRepository.setToken(this.token);
    }
    this.currentResetPollStep = 0;
  }

  async startView(): Promise<void> {
    hideAll();
    show("poll-form");

    this.initView();
    this.loadCommunity();
    await this.loadSession();
    this.registerHandlers();
    await this.loadPoll();
    await this.validateView();
  }

  public destroy(): void {}

  private initView(): void {
    if (this.token) {
      show("configuration-form");
      document.getElementById("addin-description").style.display = "none";
    } else {
      hide("configuration-form");
      document.getElementById("addin-description").style.display = "inline";
    }
    if (this.action === "open") {
      this.toggleCloseInAndShowRealtime(true);
    } else {
      this.toggleCloseInAndShowRealtime(false);
    }
  }

  private registerHandlers(): void {
    this.tokenInputHandler();
    this.sessionUidInputHandler();
    this.pollDropdownHandler();
    this.actionDropdownHandler();
    this.durationDropdownHandler();
    this.showQRCodeCheckboxHandler();
    this.previewButtonHandler();
    this.resetPollButtonHandler();
  }

  private tokenInputHandler(): void {
    this.validateToken();
    (document.getElementById("token-input") as HTMLInputElement).value = this.token || "";
    (document.getElementById("token-input") as HTMLInputElement).oninput = async (e: Event) => {
      this.token = e.target.value;

      const isValid = this.validateToken();
      if (isValid) {
        this.gqlRepository.setToken(this.token);
        this.loadCommunity();
        document.getElementById("addin-description").style.display = "none";
      } else {
        document.getElementById("addin-description").style.display = "inline";
      }

      this.save();
    };
  }

  private sessionUidInputHandler(): void {
    document.getElementById("session-name").innerText = this.session?.name || "";
    (document.getElementById("session-uid-input") as HTMLInputElement).value = this.sessionUid || "";
    (document.getElementById("session-uid-input") as HTMLInputElement).oninput = async (e: Event) => {
      this.sessionUid = e.target.value;

      if (!this.activeDebouncer) {
        this.activeDebouncer = setTimeout(async () => {
          await this.loadSession();
          this.populatePollsDropdown();
          await this.loadPoll();
          await this.validateView();

          document.getElementById("session-name").innerText = this.session?.name || "";

          this.save();
          this.activeDebouncer = undefined;
        }, 400) as unknown as number;
      }
    };
  }

  private pollDropdownHandler(): void {
    const pollsDropdown = document.getElementById("poll-dropdown") as HTMLSelectElement;
    if (!pollsDropdown) {
      return;
    }

    this.populatePollsDropdown();
    pollsDropdown.onchange = async (e) => {
      this.pollUid = e.target.value;
      this.save();
      await this.loadPoll();
      this.validateView();
    };
  }

  private populatePollsDropdown(): void {
    const pollsDropdown = document.getElementById("poll-dropdown") as HTMLSelectElement;
    if (!pollsDropdown) {
      return;
    }

    // clear all options
    pollsDropdown.innerHTML = "";
    if (this.session?.polls.length > 0) {
      this.session.polls.forEach((poll: Poll) => {
        const optionElement = document.createElement("option");
        optionElement.value = poll.uid;
        optionElement.innerText = poll.title;
        pollsDropdown.appendChild(optionElement);
      });
      if (this.pollUid) {
        const foundPoll = this.session.polls.find((poll) => poll.uid === this.pollUid);
        if (!foundPoll) {
          this.pollUid = this.session.polls[0].uid;
        }
      } else {
        this.pollUid = this.session.polls[0].uid;
      }
    } else {
      this.pollUid = "";
    }

    pollsDropdown.value = this.pollUid || "";
  }

  private actionDropdownHandler(): void {
    const actionDropdown = document.getElementById("action-dropdown") as HTMLSelectElement;
    if (!actionDropdown) {
      return;
    }
    actionDropdown.value = this.action || "";

    actionDropdown.onchange = (e) => {
      this.action = e.target.value;
      if (this.action === "open") {
        this.toggleCloseInAndShowRealtime(true);
      } else {
        this.toggleCloseInAndShowRealtime(false);
      }
      this.save();
      this.validateView();
    };
  }

  private durationDropdownHandler(): void {
    const durationInput = document.getElementById("duration-input") as HTMLInputElement;
    const durationUnitDropdown = document.getElementById("duration-unit-dropdown") as HTMLSelectElement;
    if (!durationUnitDropdown) {
      return;
    }

    if (!this.closeInValue && !this.closeInUnit) {
      this.closeInValue = "20";
      this.closeInUnit = "s";
      this.save();
    }
    durationInput.value = this.closeInValue;
    durationUnitDropdown.value = this.closeInUnit;

    durationInput.oninput = (e: Event) => {
      this.closeInValue = e.target.value;
      this.save();
    };
    durationUnitDropdown.onchange = (e) => {
      this.closeInUnit = e.target.value;
      this.save();
    };
  }

  private showQRCodeCheckboxHandler(): void {
    const showQRCodeCheckbox = document.getElementById("show-qrcode-checkbox") as HTMLInputElement;

    if (!showQRCodeCheckbox) {
      return;
    }
    showQRCodeCheckbox.checked = this.showQRCode;

    showQRCodeCheckbox.onchange = (e) => {
      this.showQRCode = (e.target as HTMLInputElement).checked;
      this.save();
    };
  }

  private presentationTitleHandler(): void {
    (document.getElementById("presentation-title-input") as HTMLInputElement).value = this.presentationTitle || "";
    (document.getElementById("presentation-title-input") as HTMLInputElement).oninput = (e: Event) => {
      this.presentationTitle = e.target.value;
      this.save();
    };
  }

  private presentationSubtitleHandler(): void {
    (document.getElementById("presentation-subtitle-input") as HTMLInputElement).value =
      this.presentationSubtitle || "";
    (document.getElementById("presentation-subtitle-input") as HTMLInputElement).oninput = (e: Event) => {
      this.presentationSubtitle = e.target.value;
      this.save();
    };
  }

  private previewButtonHandler(): void {
    document.getElementById("poll-preview").onclick = () => {
      this.destroy();
      const parameters = new ViewParameters(
        this.token,
        this.sessionUid,
        this.pollUid,
        this.action,
        this.closeInValue && this.closeInUnit ? `${this.closeInValue}${this.closeInUnit}` : undefined,
        this.showResults,
        this.activeView,
        this.slideNum,
        this.presentationTitle,
        this.presentationSubtitle,
        this.showQRCode,
        this.qrCodeUrl
      );
      const pollView = new PollController(parameters);
      pollView.startView();
    };
  }

  private resetPollButtonHandler(): void {
    document.getElementById("btn-poll-reset").onclick = async () => {
      this.currentResetPollStep = 1;
      this.validateView();
    };
    document.getElementById("btn-poll-reset-confirm-no").onclick = async () => {
      this.currentResetPollStep = 0;
      this.validateView();
    };
    document.getElementById("btn-poll-reset-confirm-yes").onclick = async () => {
      const result = await this.gqlRepository.mutate(POLL_UPDATE_MUTATION, {
        entity: {
          uid: this.poll.uid,
          startTime: null,
          endTime: null,
        },
      });
      if (result.data) {
        Promise.all(
          this.poll.pollAnswers
            .map((answer) => {
              return answer.pollUserAnswers.map((userAnswer) => {
                return this.gqlRepository.mutate(POLL_USER_ANSWER_DELETE_MUTATION, {
                  uid: userAnswer.uid,
                });
              });
            })
            .flat()
        ).then(async () => {
          await this.loadPoll();
          this.validateView();
          show("poll-url-info");
          document.getElementById("poll-url-info").innerText = "Poll is reset";
          setTimeout(() => {
            document.getElementById("poll-url-info").innerText = "";
            hide("poll-url-info");
          }, 4000);
        });
      }
      this.validateView();
    };
  }

  private save(): Promise<void> {
    const payload = new ViewParameters(
      this.token,
      this.sessionUid,
      this.pollUid,
      this.action,
      this.closeInValue && this.closeInUnit ? `${this.closeInValue}${this.closeInUnit}` : "",
      this.showResults,
      this.activeView,
      this.slideNum,
      this.presentationTitle,
      this.presentationSubtitle,
      this.showQRCode,
      this.qrCodeUrl
    );
    return PowerpointHelper.saveSetting(getLocalStorageKey(this.slideNum), JSON.stringify(payload));
  }

  validateToken(): boolean {
    const isValid = isTokenValid(this.token);
    const errorElement = document.getElementById("token-error");
    if (isValid) {
      show("configuration-form");
      errorElement.innerText = "";
    } else {
      hide("configuration-form");
      errorElement.innerText = "This token is invalid";
    }
    return isValid;
  }

  async validateView(): Promise<void> {
    // session validation
    const sessionValidation = await this.validateSession();
    const sessionErrorElement = document.getElementById("session-error");
    // reset validation
    sessionErrorElement.style.display = "none";
    // execute validation
    if (!sessionValidation.isValid) {
      sessionErrorElement.style.display = "block";
      sessionErrorElement.innerText = sessionValidation.reason;
    }

    // poll validation
    const pollValidation = await this.validatePoll();
    const pollUrlErrorElement = document.getElementById("poll-url-error");
    // reset validation
    pollUrlErrorElement.style.display = "none";
    document.getElementById("action-error").innerText = "";
    hide("btn-poll-reset");
    hide("btn-poll-reset-confirm-yes");
    hide("btn-poll-reset-confirm-no");
    // execute validation
    if (pollValidation.isValid) {
      this.currentResetPollStep = -1;
    } else {
      pollUrlErrorElement.style.display = "block";
      pollUrlErrorElement.innerText = pollValidation.reason;
    }
    this.toggleCloseInAndShowRealtime(this.action === "open");
    if (pollValidation.action === "resetPoll" && this.poll.isPublished) {
      this.toggleCloseInAndShowRealtime(false);
      if (this.currentResetPollStep === -1) {
        this.currentResetPollStep = 0;
      }
      if (this.currentResetPollStep === 0) {
        show("btn-poll-reset");
      }
      if (this.currentResetPollStep === 1) {
        pollUrlErrorElement.innerText = "Are you sure? This will delete all the exiting poll's votes";
        show("btn-poll-reset-confirm-yes");
        show("btn-poll-reset-confirm-no");
      }
      if (this.action === "open") {
        document.getElementById("action-error").innerText =
          "This action will not be executed because the poll was already used";
      }
    }
  }

  private async loadCommunity(): Promise<void> {
    this.community = undefined;
    if (!this.token) {
      return;
    }
    const payload = decodeToken(this.token);
    if (!payload) {
      return;
    }
    const response = await this.gqlRepository.query(COMMUNITY_GET_QUERY, {
      _locale: "en_US",
      _cachable: false,
      code: payload.c,
    });
    if (!response.data) {
      return;
    }

    const communities = (response.data as { community: Community[] }).community;
    if (!!communities && communities.length > 0) {
      this.community = Community.hydrate(communities[0]);
      this.qrCodeUrl = this.community.pollSessionPathQRCode || "";
    }
  }

  private async loadPoll(): Promise<void> {
    this.poll = undefined;
    if (!this.pollUid) {
      return;
    }
    const response = await this.gqlRepository.query(POLL_GET_QUERY, {
      _locale: "en_US",
      _cachable: false,
      filter: {
        uid: this.pollUid,
      },
    });
    if (!response.data) {
      return;
    }

    const polls = (response.data as { poll: Poll[] }).poll;
    if (!!polls && polls.length > 0) {
      this.poll = Poll.hydrate(polls[0]);
    }
  }

  private async loadSession() {
    this.session = undefined;
    if (!this.sessionUid) {
      return;
    }
    const response = await this.gqlRepository.query(SESSION_GET_QUERY, {
      _locale: "en_US",
      _cachable: false,
      uid: this.sessionUid,
    });
    if (!response.data) {
      return;
    }

    const sessions = (response.data as { session: Session[] }).session;
    if (!!sessions && sessions.length > 0) {
      this.session = Session.hydrate(sessions[0]);
    }
  }

  async validateSession(): Promise<{ isValid: boolean; reason?: string; action?: string }> {
    if (this.sessionUid && !this.session) {
      return {
        isValid: false,
        reason: "No session matches this uid",
      };
    }
    return {
      isValid: true,
    };
  }

  async validatePoll(): Promise<{ isValid: boolean; reason?: string; action?: string }> {
    if (this.pollUid && !this.poll) {
      return {
        isValid: false,
        reason: "This poll does not exist",
      };
    }

    if (this.poll?.isPublished) {
      return {
        isValid: false,
        reason: "This poll was already used",
        action: "resetPoll",
      };
    }
    return {
      isValid: true,
    };
  }

  toggleCloseInAndShowRealtime(enable: boolean): void {
    (document.getElementById("duration-input") as HTMLInputElement).disabled = !enable;
    (document.getElementById("duration-unit-dropdown") as HTMLSelectElement).disabled = !enable;
    if (enable) {
      document.getElementById("duration-input-label").classList.remove("color-disabled");
    } else {
      document.getElementById("duration-input-label").classList.add("color-disabled");
    }
  }
}
