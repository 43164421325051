import FileResource from "../models/fileResource";
import configs from "./configs";

export default class FileResourceHelper {
  static communityCode = "";

  /**
   * Get the full path from a resourceFile type
   * @param fileResource
   * @param size
   * @param defaultImg
   * @param isDoc
   */
  static getFullPath(fileResource?: FileResource, size?: string, defaultImg?: string, isDoc = false): string {
    if (fileResource && fileResource.path) {
      const edition = this.getEditionUrlName(fileResource);
      let { path } = fileResource;
      if (!isDoc) {
        path = this.getImagePathWithSize(path, size);
      }
      const domain = configs.RESOURCE_FILE_DOMAIN;
      const protocol = "https";
      return `${protocol}://${edition}.${domain}${path}`;
    }

    return defaultImg || "";
  }

  static getImagePathWithSize(path: string, size: string | undefined): string {
    let newPath = path;
    if (path && size) {
      if (path.indexOf("var-size/") !== -1) {
        const oldSize = path.split("var-size/")[1].split("/")[0];
        newPath = path.replace(oldSize, size);
      } else {
        newPath = path.replace("/public/var/", `/public/var-size/${size}/`);
      }
    }
    return newPath;
  }

  static getEditionUrlName(fileResource: FileResource): string {
    let edition = fileResource.schemaCode || FileResourceHelper.communityCode || "";
    if (fileResource.edition?.urlName) {
      edition = fileResource.edition?.urlName;
    }
    return edition;
  }

  static getSrcsets(src: FileResource, mobileSize: string, tabletSize: string, desktopSize: string): string {
    return `
      ${this.getSrcset(src, mobileSize)},
      ${this.getSrcset(src, tabletSize)},
      ${this.getSrcset(src, desktopSize)}
    `;
  }

  static getSrcset(src: FileResource, size: string): string {
    return `${FileResourceHelper.getFullPath(src, `w${size}`)} ${size}w`;
  }

  static getSizes(mobileSize: string, tabletSize: string, desktopSize: string): string {
    return `(max-width: 767.98px) ${mobileSize}, (max-width: 991.98px) ${tabletSize}, ${desktopSize}`;
  }
}
