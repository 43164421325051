export default `mutation PollUserAnswerDelete($uid: ID) {
  PollUserAnswerDelete(uid: $uid) {
    ...pollUserAnswerBaseFragment
  }
}

fragment pollUserAnswerBaseFragment on PollUserAnswer {
  uid
  pollAnswer {
    ...pollAnswerBaseFragment
  }
}

fragment pollAnswerBaseFragment on PollAnswer {
  uid
  id
  title
  answerCount
  displaySequence
  correctAnswer
}
`;