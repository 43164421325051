import Poll from "./poll";
import PollUserAnswer from "./pollUserAnswer";
import Base from "./base";

export default class PollAnswer extends Base {
  id?: number;

  // community?: Community;

  title?: string;

  poll?: Poll;

  pollUserAnswers?: Array<PollUserAnswer>;

  answerCount?: number;

  displaySequence?: number;

  correctAnswer: boolean;

  static hydrate(item: object): PollAnswer {
    const result = new PollAnswer();
    Object.assign(result, item);
    return result;
  }
}
