import { Client, fetchExchange, gql, OperationResult, OperationResultSource, subscriptionExchange } from "@urql/core";
import { SubscriptionClient } from "subscriptions-transport-ws";

export default class GraphqlRepository {
  private client: Client;
  private token: string;

  constructor(url: string, wsUrl?: string) {
    this.token = "";
    let subscriptionClient: SubscriptionClient | undefined;

    const exchanges = [fetchExchange];
    if (wsUrl) {
      subscriptionClient = new SubscriptionClient(wsUrl, { reconnect: true });
      exchanges.push(
        subscriptionExchange({
          forwardSubscription: (request) => subscriptionClient.request(request),
        })
      );
    }

    this.client = new Client({
      url,
      exchanges,
      fetchOptions: () => {
        return {
          headers: { authorization: this.token ? `Bearer ${this.token}` : "", "Jwt-Token": this.token },
        };
      },
    });
  }

  setToken(token: string) {
    this.token = token;
  }

  async query(gqlQuery: string, variables: object): Promise<OperationResultSource<OperationResult<object, object>>> {
    return this.client.query(gql(gqlQuery), variables);
  }

  async mutate(
    gqlMutation: string,
    variables: object
  ): Promise<OperationResultSource<OperationResult<object, object>>> {
    return this.client.mutation(gql(gqlMutation), variables);
  }

  subscription(params: {
    gqlSubscription: string;
    variables: object;
  }): OperationResultSource<OperationResult<object, object>> {
    return this.client.subscription(gql(params.gqlSubscription), params.variables);
  }
}
