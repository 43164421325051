import { ActiveView } from "../models/activeView";

export default class PowerpointHelper {
  public static getCurrentSlideId(): Promise<number> {
    return new OfficeExtension.Promise((resolve, reject) => {
      Office.context.document.getSelectedDataAsync(Office.CoercionType.SlideRange, function (result) {
        if (result.status === Office.AsyncResultStatus.Failed) {
          reject("getCurrentSlideId: " + result.error.message);
        } else {
          resolve(result.value.slides[0].id);
        }
      });
    });
  }

  public static getAllSlides(): Promise<{ id: string; index: number }[]> {
    return new OfficeExtension.Promise((resolve, reject) => {
      PowerPoint.run(async (context) => {
        try {
          const slides = context.presentation.slides;
          slides.load("items");
          await context.sync();
          const slideIds = slides.items.map((item, index) => ({
            id: item.id.split("#")[0],
            index,
          }));
          resolve(slideIds);
        } catch (e) {
          reject("SHERPA: getAllSlides:" + e);
        }
      });
    });
  }

  public static getActiveView(): Promise<ActiveView> {
    return new OfficeExtension.Promise((resolve, reject) => {
      Office.context.document.getActiveViewAsync(async (activeView: Office.AsyncResult<"edit" | "read">) => {
        if (activeView.status === Office.AsyncResultStatus.Failed) {
          reject(activeView.error.message);
        } else {
          resolve(activeView.value);
        }
      });
    });
  }

  public static registerActiveViewListener(callback: (activeView: ActiveView) => void) {
    Office.context.document.addHandlerAsync(Office.EventType.ActiveViewChanged, (arg) => {
      if (arg.activeView) {
        callback(arg.activeView);
      }
    });
  }

  public static saveSetting(key: string, payload: any): Promise<void> {
    return new OfficeExtension.Promise((resolve, reject) => {
      Office.context.document.settings.set(key, payload);
      Office.context.document.settings.saveAsync(function (asyncResult) {
        if (asyncResult.status == Office.AsyncResultStatus.Failed) {
          console.error(`SHERPA:: Settings save failed. ${asyncResult.error.message}`);
          reject(`SHERPA:: Settings save failed. ${asyncResult.error.message}`);
        } else {
          // the reason why I store in sessionStorage too is to make the data accessible to all the add-in instances
          // because settings is only accessible to its instance
          sessionStorage.setItem(key, payload);
          resolve();
        }
      });
    });
  }

  public static getSetting(key: string): any | null {
    return Office.context.document.settings.get(key);
  }

  public static removeSetting(key: string): void {
    return Office.context.document.settings.remove(key);
  }
}
